/* REACT IMPORTS */
import React from 'react';
/* LIBRARIES IMPORTS */
import MaterialContainer from '@mui/material/Container';
/* CUSTOM IMPORTS */
import CustomHead from './head';
import styles from  '../../styles/components/pages/container.module.scss';

export default function EmptyContainer(props) {

    let pageHeadTitle = props.title;
    if(props.titleHead) {
        pageHeadTitle = props.titleHead;
    }

    let mainStyles = styles.root;
    if(props.mainDivStyles) {
        mainStyles += " " + props.mainDivStyles;
    }

    return (
        <div className={mainStyles}>
            <CustomHead pageHeadTitle={pageHeadTitle}/>
            <MaterialContainer
                disableGutters
                className={styles.emptyContainer}
                maxWidth={props.maxWidth ? props.maxWidth : false}
            >
                {props.children}
            </MaterialContainer>
        </div>
    );
}
/* REACT IMPORTS */
import React, { useState, useEffect } from 'react';
/* LIBRARIES IMPORTS */
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';
import { signIn } from 'next-auth/react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
/* CUSTOM IMPORTS */
import EmptyContainer from "../components/pages/emptyContainer";
import styles from  '../styles/pages/login.module.scss';
import {ErrorCodesEnum} from "../lib/Enum/errorCodesEnum";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link href="/">
                BtoBTrip
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function Login(props) {
    const router = useRouter();
    const { t } = useTranslation(['login', 'errors']);
    const { enqueueSnackbar } = useSnackbar();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');

    useEffect(() => {
        // Getting the error details from URL
        if (router.query.code) {
            enqueueSnackbar("[" + ErrorCodesEnum.LOGIN_01 + "] " + t('errors:loginError_' + router.query.code), {variant: 'error'});
            setLoginError(router.query.code);
            setEmail(router.query.email);
        }
    }, [router]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        await signIn('credentials',
            {
                email: email,
                password: password,
                // successful login redirect
                callbackUrl: window.location.origin + '/',
            }
        );
    };

    return(
        <EmptyContainer>
            <div className={styles.login}>
                <div className={styles.loginVertical}>
                    <div className={styles.loginContainer}>
                        <Image
                            width={215}
                            height={367}
                            quality={100}
                            priority={true}
                            src={"/images/other/btrip-logo-vertical.png"}
                            alt={"BtoBTrip Logo"}
                        />
                        <div className={styles.loginForm}>
                            {/*<Avatar className={styles.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>*/}
                            <Typography component="h1" variant="h5">
                                {t('signInTitle')}
                            </Typography>
                            <form className={styles.form} onSubmit={handleSubmit}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label={t('fieldEmailLabel')}
                                    name="email"
                                    autoComplete="email"
                                    value={email ? email : ''}
                                    autoFocus
                                    error={loginError !== ''}
                                    onChange={(e) => setEmail(e.target.value)}
                                    helperText={loginError !== '' ? t('errors:loginError_' + loginError) : ''}
                                    sx={{
                                        input: {
                                            background: "transparent"
                                        }
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    label={t('fieldPasswordLabel')}
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    value={password ? password : ''}
                                    error={loginError !== ''}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="basicBlue"
                                    className={styles.submit}
                                    style={{marginBottom: 10, marginTop: 10}}
                                >
                                    {t('signInButton')}
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="/password-forgotten" variant="body2">
                                            {t('forgotPasswordButton')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        <Box mt={8}>
                            <Copyright />
                        </Box>
                    </div>
                </div>
            </div>
        </EmptyContainer>
    );
}

export default Login;

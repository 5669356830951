export const ErrorCodesEnum = Object.freeze({
    PRICE_AND_AVAILABILITY_01: 'T:PR_AIV:01',
    TRIP_STATUS_01: 'T:ST:01',
    TRIP_INCHARGE_01: 'T:IN_CH:01',
    TRIP_DUPLICATE_01: 'T:DUP:01',
    TRIP_CUSTOMER_ASSOCIATION_01: 'T:CUS_ASSO:01',
    TRIP_CUSTOMER_ASSOCIATION_02: 'T:CUS_ASSO:02',
    TRIP_CUSTOMER_ASSOCIATION_03: 'T:CUS_ASSO:03',
    TRIP_PARTICIPANTS_01: 'T:PART:01',
    TRIP_PARTICIPANTS_02: 'T:PART:02',
    TRIP_PARTICIPANT_PASSPORT_01: 'T:PART_PSP:01',
    TRIP_PARTICIPANT_PASSPORT_02: 'T:PART_PSP:01',
    TRIP_DATES_01: 'T:DAT:01',
    TRIP_DATES_02: 'T:DAT:02',
    TRIP_DATES_03: 'T:DAT:03',
    TRIP_DETAILS_DESTINATIONS_01: 'T:DET_DEST:01',
    TRIP_DETAILS_LANGUAGES_01: 'T:DET_LNG:01',
    TRIP_DETAILS_UPDATE_01: 'T:DET_UPD:01',
    TRIP_DETAILS_COVERPIC_01: 'T:DET_COVPIC:01',
    TRIP_DETAILS_COVERPIC_02: 'T:DET_COVPIC:02',
    TRIP_DETAILS_COPYFLIGHT_01: 'T:DET_CPFLIGHT:01',
    TRIP_DETAILS_COPYFLIGHT_02: 'T:DET_CPFLIGHT:02',
    TRIP_DETAILS_TRIPS_TEMPLATE_01: 'T:DET_T_TEMP:01',
    TRIP_DETAILS_TRIPS_TEMPLATE_02: 'T:DET_T_TEMP:02',
    TRIP_BUILDER_PRICE_AND_AVAI_01: 'T:BUI_PRICE_AVAI:01',
    TRIP_BUILDER_DAYS_01: 'T:BUI_DAYS:01',
    TRIP_BUILDER_UPDATE_TRANS_01: 'T:BUI_TRANS:01',
    TRIP_BUILDER_UPDATE_TRANS_02: 'T:BUI_TRANS:02',
    TRIP_BUILDER_UPDATE_TRANS_03: 'T:BUI_TRANS:02',
    TRIP_BUILDER_ORDER_DAYS_01: 'T:BUI_ORD_DAYS:01',
    TRIP_BUILDER_UPDATE_TRIP_01: 'T:BUI_UPD_T:01',
    TRIP_BUILDER_TRAVEL_STAGE_01: 'T:BUI_TRAV_ST:01',
    TRIP_BUILDER_PART_ASSO_01: 'T:BUI_PART_ASS:01',
    TRIP_BUILDER_DELETE_BENEFIT_01: 'T:BUI_DEL_BNFT:01',
    TRIP_BUILDER_NEAREST_BENEFIT_01: 'T:BUI_NRST_BNFT:01',
    TRIP_PRICES_TERMS_01: 'T:PR_TRMS:01',
    TRIP_PRICES_UPDATE_TRIP_01: 'T:PR_UPD_T:01',
    TRIP_PRICES_INSURANCES_01: 'T:PR_INS:01',
    TRIP_PRICES_TERM_UPDATE_01: 'T:PR_TRMS_UPD:01',
    TRIP_PRICES_TERM_DELETION_01: 'T:PR_TRMS_DEL:01',
    TRIP_PRICES_TERM_PRESETS_01: 'T:PR_TRMS_PRST:01',
    TRIP_PRICES_TERM_PRESETS_02: 'T:PR_TRMS_PRST:02',
    TRIP_LISTING_DELETION_01: 'T:LST_DEL:01',
    TRIP_LISTING_DELETION_02: 'T:LST_DEL:02',
    TRIP_TEMPLATE_CREATION_01: 'T:TMP_CRT:01',
    BENEFITS_SEARCH_QUERY_01: 'BNFT:SCH:01',
    BENEFITS_SEARCH_QUERY_02: 'BNFT:SCH:02',
    BENEFITS_SEARCH_ADD_DAY_01: 'BNFT:ADD_DAY:01',
    TERMS_DELETION_01: 'TRMS:DEL:01',
    TERMS_UPDATE_01: 'TRMS:UPD:01',
    AGENCY_PIC_UPLOAD_01: 'AGC:PIC_UP:01',
    AGENCY_PIC_DEL_01: 'AGC:PIC_DEL:01',
    LOGIN_01: 'LOG:01',
    USER_UPDATE_01: 'USR:UPD:01',
    CONTACT_01: 'CNCT:01',
    TRIPS_QUERY_01: 'TS:GET:01'
});
